import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from "../components/layout"
import PostTags from '../components/PostTags'
import Img from 'gatsby-image'
import { rhythm, scale } from '../utils/typography'
import presets, { colors } from '../utils/presets'

const postBody = {
  '.post-body h2': {  
    marginTop: rhythm(2),
    marginBottom: 0,
  },
  '.post-body h3': {  
    marginTop: rhythm(2),
  }
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout>
        <div css={{
          maxWidth: `700px`,
          margin: `auto`
        }}>
        <Helmet title={`${post.frontmatter.title} | ${siteTitle}`} />
        {/* <Img
          fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
          css={{ marginBottom: rhythm(1), maxWidth: `700px` }}
        /> */}
        <h1 css={{
          marginBottom: rhythm(1/2),
        }}>{post.frontmatter.title}</h1>
        <p css={{
          fontSize: '0.875rem',
          marginBottom: rhythm(1/2),
        }}s>{post.frontmatter.date}</p>
        <div
          css={{
            ...postBody
          }}
          className="post-body"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <p
          css={{
            ...scale(-1 / 5),
            display: 'block',
            marginBottom: 0,
            marginTop: rhythm(1),
            color: colors.ui.calm,
          }}
        >
          {post.frontmatter.date}
        </p>
        <PostTags tags={post.frontmatter.tags} />
        <hr
          css={{
            marginBottom: rhythm(1),
          }}
        />
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        project
        published
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
